@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


:root {
    --primaryFont: 'Montserrat';
    --secondaryFont: 'Exo 2';
    --limeGreenColor: #8ffd2a;
    --purpleColor: rgb(152, 42, 253);
    --backgroundColor: rgb(33, 31, 34) !important;
    --clr-neutral-100: hsl(0, 0%, 100%);
    --clr-primary-100: hsl(205, 15%, 58%);
    --clr-primary-400: hsl(215, 25%, 27%);
    --clr-primary-800: hsl(217, 33%, 17%);
    --clr-primary-900: hsl(218, 33%, 9%);
}

body {
    margin: 0;
    font-family: var(--primaryFont), -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--backgroundColor); 
    /*background-image: url('./assets/images/Background.jpg');*/
}

a {
    color: white;
    text-decoration: none;
}

.ff-secondary {
    font-family: var(--secondaryFont);
}

.c-purple {
    color: var(--purpleColor);
}

.wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-left: 7%;
    margin-right: 7%;
    align-items: center;
}

.heading {
    color: white;
    font-family: var(--secondaryFont);
    font-size: 48px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    width: 291px;
    height: 53px;
    justify-content: center;
    align-items: center;

font-style: normal;
font-weight: 700;
line-height: 111%
}

button {
    border-radius: 50px;
    width: 200px;
    background-color: var(--limeGreenColor);
    color: black;
    padding: 15px 20px 15px 20px;
    font-weight: 600;
    text-align: center;
    font-family: var(--primaryFont);
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}
.readmore > button2 {
    color: rgb(255, 255, 255);
    padding: 10px 0px;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    font-family: var(--primaryFont);
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}
.readmore:hover > button2{
    color:  var(--limeGreenColor);


}


.blink {
    animation: blink-animation 2.5s steps(5, start) infinite;
    -webkit-animation: blink-animation 3s steps(5, start) infinite;
}

@keyframes blink-animation {
    to {
        visibility: hidden;
    }
}

@-webkit-keyframes blink-animation {
    to {
        visibility: hidden;
    }
}
/* Navbar */
.navbar {
    /* background-color: var(--backgroundColor); */
    background-color: black;
    color: #fff;
    display: flex;
    align-items: center;
    font-family: var(--primaryFont);
    width: auto px;
    padding: 15px 3%;
    justify-content: center;
}

.navbar-brand {
    display: flex;
    align-items: center;
    column-gap: 1rem;
}

.navbar .toggle-btn{
    color:#fff;
    font-size: 1.5rem;
    cursor: pointer;
    display: none;
}

.navbar-brand > .title {
    font-size: 1.4rem;
    font-family: var(--secondaryFont);
    justify-content: center;
}
.navbar-items-t
{   display:block;
    align-content: center;
     /* Horizontal centering */ 

}  
.navbar-items {
    
    list-style: none;
    display: flex;
    text-transform: uppercase;
    justify-content: center;
    align-content: center;
    padding-top: 15px;
}

.navbar-items > li {
    transition: all 1s ease-in;
    margin-left: 1.62rem;
    display: flex;
    padding: 10px 26px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer!important;
    color: white;
    text-align: center;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 111%; /* 24.42px */
    text-transform: uppercase;
}

.navbar-items > li {
    text-decoration: none;
    position: relative;
    padding-bottom: 2px;
}

.navbar-items > li:not(:last-child):after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    border-bottom: 2px solid var(--purpleColor);
    transition: 0.5s;
}

.navbar-items > li:not(:last-child):hover:after {
    width: 100%;
}

.navbar-items li:first-child {
    margin-left: 0;
}

.navbar-items li:last-child {
    border-radius: 60px;
    border: 1px solid #FFF;
    border-color:white;
    color: white;
    padding: 12px 20px 12px 20px;
    font-weight: 500;
    text-align: center;
    transition: 0.3s;
}
.navbar-items li:last-child:hover {
    background-color: var(--purpleColor); /* Change the background color on hover */
    color: #fff; /* Change the text color on hover */
    border: 1px solid var(--purpleColor); /* Change the border color on hover */
}

/* Stats */
.stats {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 400;
    column-gap: 100px;
    color: white;
    text-align: center;
    margin-bottom: 2%;
    margin-top:2% ;
 
    
}
/* Old Stats
.stats {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 600;
    column-gap: 50px;
    border-radius: 90px;
    border: 1px solid var(--limeGreenColor);
    color: white;
    margin-top: 0px;
    margin-bottom: 25px;
    padding: 26px 35px;
    background-color: rgb(0, 0, 0, 0.5);
}*/
.stat-purple{
    color: var(--purpleColor);
    font-weight: 700;
}
.stats > div {
    display: flex;
    flex-direction: column;
    row-gap: 50%;
    padding: 20px 35px;
    border-radius: 22px;
    background-color: rgb(0, 0, 0, 0.5);
}
/* HERO */

.animation-container {
    overflow: hidden;
    position: relative;
}

.bottom-up-text {
    position: relative;
    opacity: 0;
    transform: translateY(30%);
    animation: bottomUp 1s ease-in-out forwards;
}

@keyframes bottomUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.hero {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
   
    
}

.hero-header {
    display: flex;
    flex-direction: column;
    align-items: center;

}
video{
   width: 100vw;
   overflow: hidden;
}

#video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 110%;
    
    object-fit: cover; /* Ensures the video covers the entire container */
    z-index: -1; /* Places the video behind other content */
    overflow: hidden;
}

.hero-header > h1 {
    margin-top: 0px;
    font-family: var(--secondaryFont);
    color: white;
    font-size: 58px;
    font-weight: 700;
    line-height: 111%;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.hero-header > button {
    display: flex;
    column-gap: 20px;
    justify-content: center;
    align-items: center;
    width: 225px;
    height: 54px;
    flex-shrink: 0;
    color: #000;
        text-align: center;
        font-family: "Poppins";
        font-size: 20.346px;
        font-style: normal;
        font-weight: 500;
        line-height: 111%;
}
.hero-header > button:hover{
    background-color: var(--purpleColor);
    color: White;
}
.hero-video {
    width: 40%;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
}

/* Overlay styles */
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding-left: 200px;
}


/* get-to-know-us */

.get-to-know-us {
    overflow: hidden;
  background-color: #2E2E2E;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 100px; /* Padding on all sides */
  margin-top: 20px;
}

/* Adjust the CSS for the first-div (assuming it contains the text) */
.first-div {
    
  display: flex;
  flex-direction: column;
  width: 100%; /* Adjust the width as needed */
  margin: 0 0; /* Center the content horizontally */
  text-align: left; /* Center the text within the div */
}

.get-to-know-us-text { 
    width: 75%;
    color: white;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 200%;
    margin-top: -40px;
}

.gettoknowheading{
    color: #FFF;
    font-family: var(--secondaryFont);
    font-size: 80.98px;
    font-style: normal;
    font-weight: 500;
    text-align: left;
    margin-top: -20px;
    width: auto;
}
.get-to-know-us > div > img {
    height: 420px;
    border-radius: 30px;
}
.companies{
    display: flex;
    background-color: black;
    color: #fff;
    height: 133px;
    width: auto;
    align-items: center;
    justify-content: space-around;
}
.company-logo {
    margin: 0 10px; /* Add some spacing between logos */
}
.company-logo:hover{
    opacity: 60%;
    cursor: pointer;
}

/* Adjust the image size if necessary */
.company-logo img {
    max-height: 100px; /* Set the maximum height for the logos */
    max-width: 100%; /* Ensure logos scale proportionally */
}

/* Services */
#our-services {
    margin-top: 0px;
}
.service-heading{
    font-family: var(--secondaryFont);
    display: flex;
    height: 100px;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    justify-content: center;
    font-size: 96.73px;
    font-style: normal;
    font-weight: 700;
    line-height: 111%;
    position: relative; /* Or another suitable position value */
    z-index: 1;
    
}
.background-container{
    width: fill;
    position: absolute;
    left: 0;
    overflow: hidden;
}
.ourservices-background-img{
    opacity: 60%;
    position: relative;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the container */
    z-index: -1;
    background: linear-gradient(0deg, #1F1F1F 0%, rgba(0, 0, 0, 0.00) 100%), rgb(44, 44, 44) 0% / cover no-repeat;

}
.services {
    display: flex;
    column-gap: 30px;
    row-gap: 25px;
    color: #FFF;
    
    font-family: Poppins;
    font-size: 22.255px;
    font-style: normal;
    font-weight: 700;
    line-height: 111%;
    flex-wrap: wrap;
    color: var(--limeGreenColor);
    justify-content: center;
    position: relative; /* Or another suitable position value */
    z-index: 1;
}

.outer-shadow-box {
    width: 285px;
    height: 140px;
    border: 2px solid var(--purpleColor);
}


.service {
    display: flex;
    align-items: center;
    position: relative;
    width: 285px;
    height: 140px;
    background:rgba(152, 42, 253, 0.60);
    transition: all 0.4s ease-in-out;
    justify-content: center;
   
}
.service img {
   
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}
.overlay2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 102, 0, 0.5); /* Initial overlay color with transparency */
    opacity: 0; /* Make it transparent initially */
    transition: opacity 0.3s; /* Add a transition for smooth hover effect */
}
.service-name {
    position: absolute;
    bottom: 5px; /* Adjust as needed to control text positioning */
    left: 5px; /* Adjust as needed to control text positioning */
    color: white; /* Text color */
    padding: 5px; /* Padding around the text */
}
.service-number{
    position: absolute;
    top: 5px; /* Adjust as needed to control text positioning */
    right: 5px; /* Adjust as needed to control text positioning */
    color: white; /* Text color */
    padding: 5px; /* Padding around the text */

}
.service:hover {
    cursor: pointer;
    background:rgba(152, 42, 253, 0);
    transform: translate(-15px, -15px);
    background-color: var(--purpleColor);
    color: white;
}

/* Footer */
footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    padding-top: 70px;
    padding-bottom: 10px;
    background-color: black;
    row-gap: 150px;

}
.footerclass {
    display: flex;
    flex-direction: row;
    column-gap: 400px;
    justify-content: center;
    align-items: center;
    color: white;
    row-gap: 5px;
}

.get-in-touch {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    color: #FFF;
    font-family: var(--primaryFont);
    font-size: 39.929px;
    font-style: normal;
    font-weight: 700;
    line-height: 111%;
}
.email {
    color: #FFF;
    font-family: var(--primaryFont);
    font-size: 39.929px;
    font-style: normal;
    font-weight: 700;
    line-height: 111%;
}
.copyright {
    color: #FFF;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 111%;
}

.contact-info {
    display: flex;
    color: #FFF;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 111%;
    text-align:left;
}

/* Team Start */
/* 
.meet-our-team-text{
    color: rgb(255, 255, 255);
    font-family: var(--secondaryFont);
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 111%;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
    justify-content: center;
}*/

.team { 
    
    height:100%;
    width: 631px;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
   
   
}
.team-grid {
    
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
  /* Styling for individual team cards */
  .team-card {
    width: 631px; /* Adjust the width to control the number of cards per row */
    padding: 20px;
  }
  .team-section{
    overflow: hidden;
   
    width: 100%;

  }

  .meetourteam-heading > .team-h{
   
    
    font-family: var(--secondaryFont);
    color: #FFF;
    text-align: center;
    font-family: Exo 2;
    font-size: 200px;
    font-style: normal;
    font-weight: 700;
    margin-top: 20px;
  }
  .meetourteam-heading > .meetour{

    position: absolute;
    left: 32%;
    align-content: center;
    
    margin-top: 20px;
    font-family: var(--secondaryFont);
    color: #B3FF2D;
    text-align: center;
    font-family: Exo 2;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
   
    
  
  }
  
/* old styling
/*.team-card {
    display: flex;
    width: 631px;
    column-gap: 100px;
    height: 201.331px;
    justify-content: center;
    align-items: center;
}*/

.team-card > .about-section {
    display: flex;
    background-color: black;
    border-radius: 12px;
    column-gap: 20px;
    height: 220px;
}

.about-section-image {
    position: relative;
}

.about-section > .about-section-image > .circle {
    position: absolute;
    right: 0;
    top: -10px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: var(--limeGreenColor);
}

.about-section-image-inner {
    background-color:var(--purpleColor);
    width: 200px;
    height: 100%;
     border-radius: 12px;
    overflow: hidden;
}

.about-section-image-inner > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.about-section-text {
    display: flex;
    flex-direction: column;
    padding-top: 12px;
    padding-right: 12px;
    color: white;
    row-gap: 3px;
}

.about-section-text > .name-social-icons-wrapper {
    display: flex;
    justify-content: space-between;
}

.name-social-icons-wrapper > .name {
    text-transform: uppercase;
    font-size: 18.5px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.name-social-icons-wrapper > .social-icons {
    display: flex;
    column-gap: 7px;
    color: white;
    margin-right: 18px;
}

.social-icons > .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
    border-radius: 3px;
    width: 25px;
    background-color: var(--purpleColor);

    -webkit-mask-image: linear-gradient(45deg, #ffffff 25%, rgba(255, 255, 255, 0.6) 50%, #ffffff 100%);
    mask-image: linear-gradient(45deg, #ffffff 25%, rgba(255, 255, 255, 0.6) 50%, #ffffff 100%);
    -webkit-mask-size: 800%;
    mask-size: 800%;
    -webkit-mask-position: 0;
    mask-position: 0;
    animation: maskAnimation 4s linear infinite; /* Adjust the duration as needed */
}

@keyframes maskAnimation {
    0% {
        -webkit-mask-position: 0;
        mask-position: 0;
    }
    100% {
        -webkit-mask-position: 120%;
        mask-position: 120%;
    }
} 

.about-section-text > .title {
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 10px;
    font-weight: 400;
}

/* ScrollerAnimation.css */
.Background-scroller{
        width: 100%;
        background-color: rgb(152, 42, 253, 0.1);
        margin-top: 50px;
        padding-bottom: 50px;
        
}
.scroller {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
    
  }
  
  .text-scroller h2 {
    font-size: 75px;
    color: #ffffff;
    text-align: center;
    font-family: var(--secondaryFont);
    position: relative;
    z-index: 1;
    line-height: 90%;
    padding-top: 10px;
  }
  .graphics-design-button{
    position: absolute;
    top:0;
    transform: translate(0%,100%);
    z-index: 2;
    padding: 10px;
    background: #8ffd2a;
    border-radius: 10px;
    

  }
  .text-scroller {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    margin-bottom: 50px;
    width: 100%;
    height: 100%;
    text-align: center;
    position: relative;
   

  }
  
  .image-scroller {
    display: flex;
    align-items: center;
    animation: scroll 35s linear infinite ;
    position: relative;
    height: 100%; /* Adjust the height as needed */
    width: max-content;
    overflow: hidden;
     
    
  }
  .image-scroller:hover {
    animation-play-state: paused;
  }
  .image-container {
    display: flex;

  }
  
  .image-item {
    width: 400px; /* Adjust the width as needed */
    height: 400px; /* Adjust the height as needed */
    margin-right: 20px;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    
  }
  
  .image-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(-40%);
    }
    100% {
      transform: translateX(25%);
    }
  }
  ----------
.contact-section {
    padding: 10px;
    background-color: var(--limeGreenColor);
    border-radius: 40px;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
}

.contact-section:hover {
    cursor: pointer;
}

.intro {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.arrow {
    transform: rotate(90deg);
    cursor: pointer;
}

.arrow span {
    display: block;
    width: 20px;
    height: 20px;
    border-bottom: 5px solid var(--limeGreenColor);
    border-right: 5px solid var(--limeGreenColor);
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 4s infinite;
}

.arrow span:nth-child(2) {
    animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
    animation-delay: -0.4s;
}

@keyframes animate {
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-20px, -20px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(45deg) translate(20px, 20px);
    }
}

/* Team End */

.dropdown-menu{
    display: none;
    position: absolute;
    top: 70px;
    width: 100%;
    text-transform: uppercase;
    color: white;
    background-color: rgba(255,255,255, 0.1);
    backdrop-filter: blur(15px);
    border-radius: 0 px;
    overflow: hidden;
    height: 0px;
    /* transition: height 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275); */
}

.dropdown-menu li{
    padding: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropdown-menu.open{
    height: 30%;
}

.dropdown-menu li:last-child {
    border-radius: 50px;
    background-color: black;
    color: black;
    padding: 12px 20px 12px 20px;
    font-weight: 600;
    text-align: center;
}
---------------------------------------------------

  
  .Nscroller__inner {
    display: flex;
  }
  
  .Nscroller{
    max-width: 100%;
    overflow: hidden;
    -webkit-mask: linear-gradient(
      90deg,
      transparent,
      white 20%,
      white 80%,
      transparent
    );
    mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
  }
  
  .Nscroller .Nscroller__inner {
    padding-block: 1rem;
    display: flex;
    flex-wrap: nowrap;
    gap: 1rem;
    width: max-content;
    flex-wrap: nowrap;
    animation: scroll  50s
      var(--_animation-direction, forwards) linear infinite;
  }
  
  .Nscroller{
    --_animation-direction: reverse;
  }
  
  .Nscroller{
    --_animation-direction: forwards;
  }

  /*scroller-animation-controller-keyframes*/
  @keyframes scroll {
    to {
      transform: translate(calc(-50% - 0.5rem));
    }
  }
  .fixedImage{
    height: 400px;
    width: 400px;
  }
  
  /* general styles */
  
  .Background-Nscroller{
    display: grid;
    min-block-size: 100vh;
    place-content: center;
    font-family: system-ui;
    font-size: 1.125rem;
  }
  .Background-Nscroller h1{
    color: white;
    text-align: center;
  }
  .tag-list {
    margin: 0;
    padding-inline: 0;
    list-style: none;
  }
  
  .tag-list li {
    padding: 1rem;
    background: var(--clr-primary-400);
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem -0.25rem var(--clr-primary-900);
  }
  
  /* for testing purposed to ensure the animation lined up correctly */
  .test {
    background: red !important;
  }
  
  
/* Responsive Design */
@media(max-width: 992px) {
    .overlay{
        padding-left: 100px;
    }

    .different-heading{
        font-size: 30px;
    }

    .navbar-items{
        display: none;
    }

    .navbar .toggle-btn{
        display: block;
    }

    .dropdown-menu{
        display: block;
        z-index:1;
    }

    .service{
        display: flex;
        justify-content: center;
    }

    footer > .email {
        font-size: 30px;
    }

    .get-to-know-us > div > img{
        display: none
    }

    .get-to-know-us{    
        column-gap: 0px;
    }

    .get-in-touch{
        font-size: 25px;
    }
}



  @media (max-width: 767px) {
   /* Stats */


    .hero-image{
        height: 100%;
    }

    .hero-video{
        display: none;
    }

    .hero-header{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .dropdown-menu{
        z-index: 1;
    }

    .container {
        flex-direction: column;
    }

  
    
    .line {
        margin: 20px 0;
        transform: rotate(90deg); /* Rotate the line vertically */
        transform-origin: center; /* Rotate around its center */
        background-image: linear-gradient(to right, var(--purpleColor), var(--limeGreenColor));
        margin-top: 30px;
        margin-bottom: 30px;
    }
  }

  @media (max-width: 525px) {
    html, body{
        overflow-x: hidden;
    }
    /*NAVBAR*/
    .navbar {
        /* background-color: var(--backgroundColor); */
        background-color: black;
        color: #fff;
        display: flex;
        justify-content: space-between;
        font-family: var(--primaryFont);
        width: auto;
        align-items: center;
    }
    
    .navbar-brand {
        display: flex;
        align-items: center;
        column-gap: 1rem;
      
    }
    .navbar .toggle-btn{
        color:#fff;
        font-size: 1.5rem;
        cursor: pointer;
        
    }
    .stats {
        display: none;
        flex-wrap: wrap;
        text-transform: uppercase;
        width: 100%;
        font-size: 15px;
        font-weight: 600;
        row-gap: 10px;
        border-radius:0px;
        border: 1px solid var(--limeGreenColor);
        color: white;
        margin-top: 0px;
        background-color: rgb(0, 0, 0, 0.5);
    }
    
    .stats > div {
        display: flex;
        column-gap: 25px;
    }
    .hero-header > h1 {
        margin-top: 20px;
        font-family: var(--secondaryFont);
        color: white;
        font-size: 50px;
        font-weight: 700;
        line-height: 111%;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
button{
    margin-bottom: 20px;
}

   

   
    .video-container {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    
    #video-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 75%;
        scale: 1;
        object-fit: cover; /* Ensures the video covers the entire container */
        z-index: -2; /* Places the video behind other content */
    }
    
    /* Services */
#our-services {
    margin-top: 0px;
}
.service-heading{
    font-family: var(--secondaryFont);
    display: flex;
    height: 100px;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    justify-content: center;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    position: relative; /* Or another suitable position value */
    z-index: 1;
    
}
.background-container{
    width: auto;
    position: absolute;
    left: 0;
    overflow: hidden;
}
.ourservices-background-img{
    opacity: 60%;
    position: relative;
    left: 0;
    width: 300%;
    height: 300%;
    object-fit: cover; /* Ensures the image covers the container */
    z-index: -1;
    background: linear-gradient(0deg, #1F1F1F 0%, rgba(0, 0, 0, 0.00) 100%), rgb(44, 44, 44) 0% / cover no-repeat;

}
.services {
    display: flex;
    column-gap: 30px;
    row-gap: 25px;
    color: #FFF;
    font-family: Poppins;
    font-size: 22.255px;
    font-style: normal;
    font-weight: 700;
    line-height: 111%;
    flex-wrap: wrap;
    color: var(--limeGreenColor);
    justify-content: center;
    position: relative; /* Or another suitable position value */
    z-index: 1;
    margin-top: -40px;
}
.service:hover {
    cursor: pointer;
    background:rgba(152, 42, 253, 0);
    transform: none;
    
    color: white;
}



.service {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    background:rgba(152, 42, 253, 0.60);
    transition: all 0.4s ease-in-out;
    justify-content: center;
   
}


/* Team Start */
.meetourteam-heading > .team-h{
    position: relative;
    width: 100%;
    height: 100px;
    
    font-family: var(--secondaryFont);
    color: #FFF;
    text-align: center;
    font-family: Exo 2;
    font-size: 100px;
    font-style: normal;
    font-weight: 700;
   margin-top:-20px ;
   
  }
  
  .meetourteam-heading > .meetour{
   left: 0%;
   align-content: center;
   position: relative;
   margin-top: 0px;
    font-family: var(--secondaryFont);
   
    color: #B3FF2D;
    text-align: center;
    font-family: Exo 2;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    
    
  
  }
  .meet-our-team-heading{

    overflow: hidden;
    height:100%;
    width:100%;
  }

.team { 
   
    height:100%;
    width: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
   
   
}
.team-grid {
    max-width: 100%;
    max-height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .team-card {
    width: auto; /* Adjust the width to control the number of cards per row */
    
  }
  .team-section{
    overflow: hidden;
    margin-top: 30px;
    width: auto;
    height: auto;
  }
  .team-card > .about-section {
    display: flex;
    background-color: black;
    border-radius: 12px;
    
    height: 100%;
}
.intro{
    display: none;
}
.about-section-image {
    position: relative;
}

.about-section > .about-section-image > .circle {
    display: none;
}

.about-section-image-inner {
    background-color:var(--purpleColor);
    width: 100px;
    height: 100%;
     border-radius: 12px;
    overflow: hidden;
}

.about-section-image-inner > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.about-section-text {
    display: flex;
    flex-direction: column;
    padding-top: 12px;
    padding-right: 12px;
    color: white;
    row-gap: 10px;
}

.about-section-text > .name-social-icons-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    justify-content: space-between;
}

.name-social-icons-wrapper > .name {
    text-transform: uppercase;
    font-size: 18.5px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.name-social-icons-wrapper > .social-icons {
    display: flex;
    column-gap: 7px;
    color: white;
    margin-right: 18px;
}

.social-icons > .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
    border-radius: 3px;
    width: 60px;
    height: 60px;
    background-color: var(--purpleColor);

    -webkit-mask-image: linear-gradient(45deg, #ffffff 25%, rgba(255, 255, 255, 0.6) 50%, #ffffff 100%);
    mask-image: linear-gradient(45deg, #ffffff 25%, rgba(255, 255, 255, 0.6) 50%, #ffffff 100%);
    -webkit-mask-size: 800%;
    mask-size: 800%;
    -webkit-mask-position: 0;
    mask-position: 0;
    animation: maskAnimation 4s linear infinite; /* Adjust the duration as needed */
}

@keyframes maskAnimation {
    0% {
        -webkit-mask-position: 0;
        mask-position: 0;
    }
    100% {
        -webkit-mask-position: 120%;
        mask-position: 120%;
    }
} 

.about-section-text > .title {
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 10px;
    font-weight: 400;
}
  /* get-to-know-us */

.get-to-know-us {
    background-color: #2E2E2E;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px; /* Padding on all sides */
    margin-top: 20px;
  }
  
  /* Adjust the CSS for the first-div (assuming it contains the text) */
  .first-div {
    display: flex;
    flex-direction: column;
    width: 100%; /* Adjust the width as needed */
    margin: 0 0; /* Center the content horizontally */
    text-align: left; /* Center the text within the div */
  }
  
  .get-to-know-us-text { 
      color: white;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 200%;
      margin-top: -20px;
  }
  
  .gettoknowheading{
      color: #FFF;
      font-family: var(--secondaryFont);
      font-size: 50px;
      font-style: normal;
      font-weight: 500;
      text-align: left;
      margin-top: -20px;
      width: auto;
  }
  .get-to-know-us > div > img {
    display: none;
      height: 420px;
      border-radius: 30px;
  }
  /* Footer */
footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    padding-top: 70px;
    padding-bottom: 10px;
    background-color: black;
    row-gap: 40px;

}
.footer-image{
    margin-top: 20px;
}
.footerclass {
    display: flex;
    flex-direction: column;
    column-gap: 10px;
    justify-content: center;
    align-items: center;
    color: white;
    row-gap: 5px;
}

.get-in-touch {
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    color: #FFF;
    font-family: var(--primaryFont);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 111%;
}
.email {
    color: #FFF;
    font-family: var(--primaryFont);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 111%;
}
.copyright {
    text-align: center;
    color: #FFF;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 111%;
}

.contact-info {
    display: flex;
    color: #FFF;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 111%;
    text-align:center;
    align-content: center;
}
  }
  
