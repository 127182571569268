.testimonial-section{
	display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-left: 7%;
    margin-right: 7%;
    align-items: center;

}
.carousel-container {
	width: 700px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 1;
	
}

.carousel-wrapper {
	width: 700px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: hidden;
	border-radius: 10px;
	

}

.carousel {
	height: 100%;
	display: flex;
	transition: transform 0.5s ease;
	margin: 0;
  	padding: 0;
}

.carousel-item {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 24px;
	
}

.carousel-dots {
	display: flex;
	margin-top: 2%;
	z-index: 1;
}
.testimonial-slide {
	display: flex;
	flex: 0 0 100%;
	box-sizing: border-box;
	justify-content: center;
	transition: transform 0.5s ease;
	height: 100%; /* Set a fixed height for the testimonial cards */
  }

.dot {
	display: none;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin: 0 5px;
	background-color: white;
}

.dot.active {
	background-color: #8ffd2a
}

.arrow-right {
	width: 10px;
	height: 10px;
	opacity: 60%;
	border: solid white;
	border-width: 0 4px 4px 0;
	display: inline-block;
  	padding: 3px;
	transform: rotate(-45deg);
  	-webkit-transform: rotate(-45deg);
	cursor: pointer;
	z-index: 1;
}
.arrow-right:hover{
	opacity: 100%;
	width: 10px;
	height: 10px;
	border: solid var(--limeGreenColor);
	border-width: 0 4px 4px 0;
	display: inline-block;
  	padding: 3px;
	transform: rotate(-45deg);
  	-webkit-transform: rotate(-45deg);
	cursor: pointer;
	z-index: 1;
}
.arrow-right:active{
	width: 10px;
	height: 10px;
	border: solid var(--limeGreenColor);
	border-width: 0 4px 4px 0;
	display: inline-block;
  	padding: 3px;
	transform: rotate(-45deg);
  	-webkit-transform: rotate(-45deg);
	cursor: pointer;
	z-index: 1;
	border-radius: 10px;
}
.arrow-left {
	opacity: 60%;
	width: 10px;
	height: 10px;
	border: solid white;
	border-width: 0 4px 4px 0;
	display: inline-block;
  	padding: 3px;
	transform: rotate(135deg);
  	-webkit-transform: rotate(135deg);
	cursor: pointer;
	z-index: 1;
}
.arrow-left:hover{
	opacity: 100%;
	width: 10px;
	height: 10px;
	border: solid var(--limeGreenColor);
	border-width: 0 4px 4px 0;
	display: inline-block;
  	padding: 3px;
	transform: rotate(135deg);
  	-webkit-transform: rotate(135deg);
	cursor: pointer;
	z-index: 1;
	
}
.arrow-left:active{
	background-color: black;
	width: 10px;
	height: 10px;
	border: solid var(--limeGreenColor);
	border-width: 0 4px 4px 0;
	display: inline-block;
  	padding: 3px;
	transform: rotate(135deg);
  	-webkit-transform: rotate(135deg);
	cursor: pointer;
	z-index: 1;
	border-radius: 10px;


}
.arrows-carousel-dots{
	height: 100%;
	display: flex;
	flex-direction: row;
	column-gap: 10px;
	align-items: center;
	justify-items: center;

}
.heading-testimonial{
	color: #FFF;
text-align: center;
font-family: Exo 2;
font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: 111%; /* 53.28px */
}

.with-video{

	display: flex;
	flex-direction: row;
	column-gap: 40px;
}
.testimonial-video{
	width: 560px;
	height: 315px;
	border-radius: 10px;
	z-index: 2;
}
.video-styling{
	
	height: max-content;
	border-radius: 10px;
	overflow: hidden;
	z-index: 2;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.iframe{
	width: 560px;
	height: 315px;
	background-color: #8ffd2a;
	padding: 10px;
}
.reviewer-about {
    margin-top: 10px;
    margin-left: 15px;
    color: white;
    text-transform: uppercase;
	
}

.testimonial-message {
	font-size: 13px;
    color: white;
    line-height: 23px;
}

.readmore{
	display:flex;
	column-gap: 10px;
	text-align: center;
	align-items: center;

}
.testimonial-container{
	border-radius: 10px;
	background-color: rgba(0, 0, 0, 0.5);
	padding: 30px; 
    min-height: 255px;
}
.reviewer-details{
	padding: 0;
	display: flex;
}
.img{
	border-radius: 50;
	border: 3px solid #8ffd2a;
	object-fit: cover;
}
.reviewer-name{
	font-size: 16px;
	font-weight: 700;
}
.reviewer-company{
	font-size: 12px;
	font-weight: 500;
}

@media (max-width: 525px) {
	.arrow-right {
		display: none;
	  }
	  .arrow-left {
		display: none;
	  }
	  .dot {
		display: none;
	  }
	  .testimonial-section {
		display: flex;
		flex-direction: column;
		row-gap: 20px;
		margin-left: 7%;
		margin-right: 7%;
		align-items: center;
		width: 100%;
	  }
	  .carousel-container {
		overflow: hidden;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		z-index: 1;
	  }
	  .carousel-wrapper {
		width: 80%;
		height: 100%;
		
		align-items: center;
		justify-content: center;
		position: relative;
		overflow: hidden;
		border-radius: 10px;
	  }
	  .carousel {
		overflow: hidden;
		width: 100%;
		height: 100%;
		display: flex;
		transition: transform 0.5s ease;
		margin: 0;
		padding: 0;
	  }
	  .carousel-item {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 24px;
	  }
	  .with-video {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		
		justify-content: center;
		width: 100%;
		height: 100%;
		
	  }
	  .testimonial-container {
		border-radius: 10px;
		background-color: rgba(0, 0, 0, 0.5);
		padding: 30px;
	  }
	  .testimonial-video {
		max-width: 100%;
		max-height: 100%;
		width: auto;
		height: auto;
		margin: auto;
		border-radius: 10px;
		z-index: 2;
	  }
	  iframe {
		height: 100%;
		width: 100%;
	  }
	  .video-styling {
		height: auto;
	  }
	
}